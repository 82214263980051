import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useEffect } from 'react';

import { getCopy } from 'store/copy.data';
import { PageProps } from 'types/page';
import { NonModulesPages, ROUTES } from 'utils/routes';

export const getStaticProps = async () => {
  const initialCopy = getCopy(NonModulesPages.autoLogout);

  const props: PageProps = {
    initialCopy: initialCopy,
    authData: null,
    currentPage: NonModulesPages.autoLogout,
    dehydratedState: null,
    initialLocale: 'en-gb',
    subdomain: '',
  };

  return {
    props,
  };
};

/**
 * Redirect to this page to perform logout action.
 * Because NextAuth doesn't provide an easy way to logout during SSR, it's easier
 * to redirect user to this page and perform logout on client side
 * */

const AutoLogout = () => {
  const router = useRouter();
  useEffect(() => {
    signOut({
      redirect: false,
    }).then(() => {
      router.push(ROUTES.LOGIN);
    });
  }, [router]);

  return null;
};

export default AutoLogout;
